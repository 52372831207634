import React, { useState, useEffect } from 'react';
import { fetchLatestDraws } from '../services/api';
import DrawCard from './DrawCard';
import DrawSearch from './DrawSearch';

const ITEMS_PER_PAGE = 5;

const LatestDraws = () => {
  const [draws, setDraws] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDraws, setTotalDraws] = useState(0);
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    const loadDraws = async () => {
      try {
        setLoading(true);
        const response = await fetchLatestDraws(currentPage, ITEMS_PER_PAGE);
        setDraws(response.draws);
        setTotalPages(response.totalPages);
        setTotalDraws(response.totalDraws);
        setError(null);
        setSearchResults(null);
      } catch (err) {
        setError('Failed to load draws');
        console.error('Error loading draws:', err);
      } finally {
        setLoading(false);
      }
    };

    loadDraws();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  // Handle search results
  const handleSearchResult = (results) => {
    setSearchResults(results);
    setCurrentPage(1);
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-[200px]">
      <span className="loading loading-spinner loading-lg text-primary"></span>
    </div>
  );
  if (error) return (
    <div className="alert alert-error">
      <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <span>{error}</span>
    </div>
  );

  const displayedDraws = searchResults || draws;

  return (
    <div className="space-y-4">
      {/* Search Component */}
      <DrawSearch 
        draws={draws} 
        onSearchResult={handleSearchResult} 
      />

      {/* No Results Message */}
      {displayedDraws.length === 0 && (
        <div className="alert alert-info">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <span>Tidak ada hasil yang ditemukan</span>
        </div>
      )}

      {/* Draws Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {displayedDraws.map((draw) => (
          <DrawCard key={draw._id} draw={draw} />
        ))}
      </div>

      {/* Pagination Controls */}
      {!searchResults && totalPages > 1 && (
        <div className="join flex justify-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`join-item btn btn-sm ${
              currentPage === 1 ? 'btn-disabled' : 'btn-ghost'
            }`}
          >
            «
          </button>
          {totalPages <= 5 ? (
            // Show all pages if total pages is 5 or less
            Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => handlePageChange(i + 1)}
                className={`join-item btn btn-sm ${
                  currentPage === i + 1 ? 'btn-primary' : 'btn-ghost'
                }`}
              >
                {i + 1}
              </button>
            ))
          ) : (
            // Show first, last, and pages around current page for more than 5 pages
            <>
              {[...Array(totalPages)].map((_, i) => {
                const pageNumber = i + 1;
                // Always show first and last page
                // Show current page and one page before and after
                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                ) {
                  return (
                    <button
                      key={i}
                      onClick={() => handlePageChange(pageNumber)}
                      className={`join-item btn btn-sm ${
                        currentPage === pageNumber ? 'btn-primary' : 'btn-ghost'
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                }
                // Show dots if there's a gap
                if (
                  pageNumber === currentPage - 2 ||
                  pageNumber === currentPage + 2
                ) {
                  return (
                    <button
                      key={i}
                      className="join-item btn btn-sm btn-ghost btn-disabled"
                    >
                      ...
                    </button>
                  );
                }
                return null;
              })}
            </>
          )}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`join-item btn btn-sm ${
              currentPage === totalPages ? 'btn-disabled' : 'btn-ghost'
            }`}
          >
            »
          </button>
        </div>
      )}
    </div>
  );
};

export default LatestDraws;
